/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
#home {
  height: 177px;
  margin-top: 37px;
}
.desk {
  width: 760px;
  max-width: 100%;
}
#wrapper {
  padding-right: 120px;
}
#services {
  display: block;
}
#services2 {
  display: none;
}
#find {
  display: block;
  margin-right: 60px;
}
#logobg {
  height: 100%;
}
#footer {
  margin-top: 70px;
}
.cb-layout2 h1 {
  max-width: 760px;
}
#view div.fold a.link.more,
#view div.flat a.link.more {
  top: 0;
}
#view div.fold div.load,
#view div.flat div.load {
  position: absolute;
  right: 21px;
  top: 15px;
  z-index: 3;
  width: 26px !important;
  margin: 0 !important;
}
#view div.fold div.load a.load:hover,
#view div.flat div.load a.load:hover,
#view div.fold div.load a.load:focus,
#view div.flat div.load a.load:focus {
  background-image: url(/images/fold-load-active.svg);
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #d59f31;
}
div.sub1 .cb-toggle:hover,
div.sub1 .cb-toggle:focus {
  background-image: url(/images/sub1-open-hover.svg);
}
div.sub1 .cb-toggle.cb-toggle-active:hover,
div.sub1 .cb-toggle.cb-toggle-active:focus {
  background-image: url(/images/sub1-close-hover.svg);
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #d59f31;
}
@media only screen and (min-width: 1600px) {
  #view #navigation,
  #edit #navigation {
    left: 0;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
  }
  #view #navigationwrapper.cb-toggle-target-active #navigation,
  #edit #navigationwrapper.cb-toggle-target-active #navigation {
    box-shadow: none;
    left: -320px;
  }
  #navigationwrapper.cb-toggle-target-active .naviover {
    display: none;
  }
}
#farnorthcontent {
  margin-top: 70px;
}
#view #farnorthcontent {
  margin-top: 0;
  height: 0;
  overflow: hidden;
}
.opennews {
  display: block;
}
#news {
  width: 320px;
  background: url(/images/news.png) repeat 0 0;
  color: #fff;
  margin-top: 0;
}
#news a {
  color: #fff;
}
#news a:hover,
#news a:focus {
  color: #d59f31;
}
#view #news {
  position: fixed;
  right: -200px;
  top: 0;
  height: 100%;
  z-index: 11;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
#view #news.cb-toggle-target-active {
  right: 120px;
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
#view.cb-layout1 #news {
  right: 120px;
}
#view.cb-layout1 #news.cb-toggle-target-active {
  right: -200px;
}
.newstitle {
  margin: 65px 40px 0;
  color: #fff;
}
.cb-authenticated .newstitle {
  margin-top: 115px;
}
div.farnorth {
  margin-top: 55px;
}
div.cb-slideshow div.head div.ctrl div.prev {
  margin-left: 30px;
}
div.cb-slideshow div.head div.ctrl div.next {
  margin-right: 30px;
}
.area {
  width: 780px;
}
.area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 780px;
}
.area h2,
.area .foot {
  margin-right: 10px;
  margin-left: 10px;
}
.area .part,
.area > .grid table {
  margin-right: 10px;
  margin-left: 10px;
  width: 760px;
}
.area .tiny {
  width: 370px;
}
.area > .slim {
  width: 390px;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 370px;
}
.area > .slim .tiny {
  margin-right: 10px;
  margin-left: 10px;
  width: 175px;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 10px;
  margin-left: 10px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
div.main {
  margin-left: -10px;
}
.cb-layout2 div.main {
  width: 100%;
  margin-left: 0;
}
.cb-layout2 div.main div.unit {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout2 div.main div.unit div.head,
.cb-layout2 div.main div.unit div.body,
.cb-layout2 div.main div.unit div.foot {
  width: 760px;
}
.cb-layout2 div.main div.unit div.part,
.cb-layout2 div.main div.unit table,
.cb-layout2 div.main div.unit div.head h2 {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout2 div.main div.unit div.part {
  width: 100%;
}
.cb-layout3 div.main div.seam {
  margin-left: 10px;
  margin-right: 10px;
  width: 760px;
}
.cb-layout3 div.main div.seam div.head h2,
.cb-layout3 div.main div.seam div.part {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout3 div.main div.seam div.part {
  width: 100%;
}
.cb-layout3 div.main div.seam.slim {
  width: 370px;
}
.cb-layout5 div.main {
  width: 100%;
  margin-left: 0;
}
.cb-layout5 div.main div.unit {
  width: 100%;
}
.cb-layout5 div.main div.unit div.head h2,
.cb-layout5 div.main div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout5 div.main div.unit div.tall {
  float: right;
  width: 460px;
}
.cb-layout5 div.main div.unit div.tiny {
  float: left;
  width: 270px;
}
div.base {
  margin-left: -10px;
}
div.base div.unit {
  margin-left: 10px;
  margin-right: 10px;
  width: 240px;
}
div.base div.unit div.head h2,
div.base div.unit div.part {
  margin-left: 10px;
  margin-right: 10px;
}
div.base div.unit div.part {
  width: 220px;
}
div.farnorth {
  width: 100%;
  margin-bottom: 48px;
}
div.farnorth h2 {
  background: url(/images/h2-farnorth.svg) no-repeat 0 0;
}
div.farnorth div.unit {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
div.farnorth div.unit div.head h2,
div.farnorth div.unit div.part {
  margin-left: 40px;
  margin-right: 40px;
}
div.farnorth div.unit div.part {
  width: 240px;
}
div.north {
  margin-left: 0;
  width: 100%;
}
div.north div.unit {
  margin-left: 0;
  margin-right: 0;
}
div.north div.unit.wide {
  width: 100%;
}
div.north div.unit.slim {
  width: 270px;
}
div.north div.unit div.head h2,
div.north div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
div.north div.unit div.part {
  width: 100%;
}
.cb-layout3 div.main div.fold,
.cb-layout3 div.main div.flat {
  margin-left: 10px;
  margin-right: 10px;
  width: 760px;
}
.cb-layout3 div.main div.fold div.head h2,
.cb-layout3 div.main div.flat div.head h2,
.cb-layout3 div.main div.fold div.part,
.cb-layout3 div.main div.flat div.part,
.cb-layout3 div.main div.fold div.foot,
.cb-layout3 div.main div.flat div.foot {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout3 div.main div.fold div.part,
.cb-layout3 div.main div.flat div.part {
  width: 100%;
}
#view.cb-layout3 div.main div.unit.seam:hover div.text p.norm {
  background: #d59f31;
  color: #fff;
}
#view div.base div.unit {
  -o-transition: all 0.18s;
  -moz-transition: all 0.18s;
  -webkit-transition: all 0.18s;
  transition: all 0.18s;
}
#view div.base div.unit:hover {
  background: #69394b;
}
#view div.base div.unit.seam:hover {
  background: #ba863f;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #50162d;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #50162d;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #280b17;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.1125em 1em;
  min-height: 1.2em;
  border: 1px solid;
  border-color: #3c1122 #14060b #14060b #3c1122;
  border-radius: 2px;
  background-color: #50162d;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
  line-height: 1.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #3c1122 #14060b #14060b #3c1122;
  background-color: #5c1934;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #3c1122 #14060b #14060b #3c1122;
  background-color: #280b17;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/******* module-filter-large.less 2013-1-16 *******/
/*.beta span > .cb-hybrid,
.days td > .cb-hybrid,
.week td > .cb-hybrid,
.year td > .cb-hybrid,
.cats li > .cb-hybrid {
  text-decoration: underline;
}*/
.mese td > .cb-hybrid {
  background-color: #5c1934;
}
.mese td > .cb-hybrid:hover,
.mese td > .cb-hybrid:focus {
  background-color: #3c1122;
}
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span,
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/*# sourceMappingURL=./screen-large.css.map */